import React, { Component } from "react"
import { withTranslation } from "react-i18next"
import { Link } from "gatsby"

import "./Menu.scss"
import SVG from "react-inlinesvg"
import star from "../../images/star.svg"
import cx from "classnames"
import { isBelarusSite } from "../../helpers/config"

// TODO: Переписать на функциональный и заменить isBelarusSite на useDomainName hook
class Menu extends Component {
  render() {
    const { t, footer, className, style } = this.props

    return (
      <ul
        style={style}
        className={cx("menu menu_" + (footer ? "footer" : "header"), className)}
      >
        {footer ? (
          <>
            <li className="menu__item">{t("Menu")}</li>
          </>
        ) : (
          ""
        )}
        {!footer && (
          <div>
            {Array.from([...Array(3).keys()]).map(idx => (
              <SVG key={idx} src={star} className={`star star${idx}`}></SVG>
            ))}
          </div>
        )}
        <li className="menu__item">
          <Link to="/">{t("Home")}</Link>
        </li>
        <li className="menu__item">
          <Link to="/portfolio">{t("Portfolio")}</Link>
        </li>
        {isBelarusSite && (
          <li className="menu__item">
            <Link to="/order">{t("Order service")}</Link>
          </li>
        )}
        {!isBelarusSite && (
          <li className="menu__item">
            <Link to="/services">{t("Services")}</Link>
          </li>
        )}
        {/*<li className="menu__item">*/}
        {/*  <a href="https://crm.sava.team/authentication/login">*/}
        {/*    {t("Account")}*/}
        {/*  </a>*/}
        {/*</li>*/}
        <li className="menu__item">
          <Link to="/#contacts">{t("Contacts")}</Link>
        </li>
      </ul>
    )
  }
}

export default withTranslation()(Menu)
