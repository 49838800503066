export const isLocalStorageSupported = () => {
  try {
    if ("localStorage" in window) {
      const testKey = "__test_key__"
      window?.localStorage?.setItem(testKey, testKey) // eslint-disable-line
      window?.localStorage?.removeItem(testKey) // eslint-disable-line
      return true
    }
    return false
  } catch (e) {
    return false
  }
}

export default { isLocalStorageSupported }
