import React, { memo } from "react"
import cx from "classnames"
// import useDarkMode from "use-dark-mode"

import * as style from "./Button.module.scss"

const Button = memo(
  ({ children, href, className, disabled, typeInput, onClick, ...props }) => {
    // если убрать мемо происходят ререндеры кнопок, я не уверен в своем решении
    const darkMode = { value: true } // TODO: useDarkMode()

    if (!href && !typeInput) {
      return (
        <button
          {...props}
          className={cx(
            style.button,
            style.button_shine,
            className,
            darkMode.value ? style.buttonDark : style.buttonLight,
          )}
          disabled={disabled}
          onClick={onClick}
        >
          {children}
        </button>
      )
    }
    if (href) {
      return (
        <a
          href={href}
          className={cx(
            style.button,
            style.button_shine,
            className,
            darkMode.value ? style.buttonDark : style.buttonLight,
          )}
        >
          {children}
        </a>
      )
    }
    if (typeInput) {
      return (
        <button
          className={cx(
            style.button_Input_shyne,
            darkMode.value ? style.button_InputDark : style.button_InputLight,
          )}
          disabled={disabled}
          onClick={onClick}
        >
          {children}
        </button>
      )
    }
  },
)
export default Button
