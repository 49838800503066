import React from "react"
import { default as CookieConsent } from "react-cookie-consent"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"

const Cookies = () => {
  const { t } = useTranslation()

  return (
    <CookieConsent
      buttonStyle={{
        backgroundColor: "var(--base-color-button-dark)",
        color: "var(--primary-text-color)",
        fontSize: "13px",
      }}
      buttonWrapperClasses="cookies-button-wrapper"
      contentClasses="cookies-content-wrapper"
      style={{ justifyContent: "flex-start", alignItems: "center" }}
      contentStyle={{ flex: "unset" }}
      buttonText={t("OK")}
    >
      {t(
        "This website uses cookies to enhance the user experience. To learn more click",
      )}{" "}
      <Link to="/cookie-policy">{t("cookie policy")}</Link>
    </CookieConsent>
  )
}
export default Cookies
