import { createSlice } from "@reduxjs/toolkit"

export const scroll = createSlice({
  name: "scroll",
  initialState: {
    isScrolled: false,
  },
  reducers: {
    setIsScrolled: (state, action) => {
      state.isScrolled = action.payload
    },
  },
})

export const { setIsScrolled } = scroll.actions

export const selectIsScrolled = state => state.scroll.isScrolled

export default scroll.reducer
