import { createTheme } from "@mui/material/styles"

export const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#413C69",
    },
    secondary: {
      main: "#637ea0",
    },
    background: {
      default: "#16163E",
    },
    text: {
      primary: "#E2E2E7",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: ({ theme }) => ({
          ".MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary":
            {
              backgroundColor: "var(--form-color-background)",
              borderBottom: "2px solid var(--base-color-input-dark)",

              "&.Mui-disabled": {
                opacity: 0.5,

                input: {
                  "-webkit-text-fill-color": "rgba(255,255,255,.8)",
                },
              },

              "&.Mui-focused": {
                borderBottom: "2px solid #3b37b3",
              },
            },

          fieldset: {
            border: "0",
          },
          "& .MuiFormControl-root.MuiTextField-root": {
            background: "transparent",
          },

          "& .MuiOutlinedInput-root": {
            padding: "0",
            "& > svg": {
              left: "28px",
              transform: "translateX(-50%)",
              position: "absolute",
              "& path": {
                fill: "#504ABE", // Цвет SVG
              },
            },
            ".MuiButtonBase-root.MuiIconButton-root:not(.MuiTelInput-IconButton)":
              {
                right: "28px",
                transform: "translateX(-50%)",
                position: "absolute",
                "& svg path": {
                  fill: "#504ABE", // Цвет SVG
                },
              },
          },
          "& .MuiOutlinedInput-root:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px transparent inset !important",
          },
          "& .MuiOutlinedInput-input": {
            padding: "18px 16px 14px 56px",
            color: "var(--form-text-color)",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "transparent",
            },
          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline:before":
            {
              borderBottomColor: "var(--base-color-hover-dark)",
            },
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: () => ({
          lineHeight: 1.2,
          ".MuiFormControlLabel-asterisk": {
            display: "none",
          },
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: () => ({
          color: "var(--primary)",
        }),
      },
    },
  },
})
