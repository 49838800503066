import React from "react"

import * as styles from "./WhatsappFloatingButton.module.scss"
import Svg from "react-inlinesvg"
import WhatsappIcon from "../../images/icons/whatsapp.svg"
import useDomainName from "../../hook/useDomainName"
import cx from "classnames"

const WhatsappFloatingButton = () => {
  const { phone, isUSASite } = useDomainName()

  const onDisabledJivo = !!isUSASite

  // TODO: Вынести второй номер USA в конфиг
  const _phone = isUSASite ? "18475046041" : phone

  return (
    <a
      href={`https://wa.me/${_phone}`}
      target="_blank"
      rel="noreferrer"
      role="button"
      tabIndex="0"
      className={cx(styles.button, onDisabledJivo ? styles.onDisabledJivo : "")}
      type="button"
    >
      <span className={styles.overlay}>
        <div className={styles.iconContainer}>
          <div>
            <Svg src={WhatsappIcon} />
          </div>
        </div>
        <span className={styles.ellipsis}>
          <div className={styles.notificationBadge} />
        </span>
      </span>
    </a>
  )
}

export default WhatsappFloatingButton
