import React, { useState, useEffect } from "react"
import "normalize.css"

import Footer from "./Footer/Footer"
import HeaderTop from "./HeaderTop"
import "../styles/common.scss"
import PageLoader from "./PageLoader"
import useDomainName from "../hook/useDomainName"
import { LoadContextProvider } from "../context/index"
import Cookies from "./Cookies"
import { hasWindow } from "../helpers/config"
import WhatsappFloatingButton from "./WhatsappFloatingButton"

const Layout = ({ children }) => {
  const [isLoaded, setLoaded] = useState(false)
  const { isUSASite } = useDomainName()

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true)
    }, 1400)

    if (!isUSASite) {
      let jivoSite = document.querySelector("[data-jv-id]")
      if (!jivoSite) {
        let script = document.createElement("script"),
          scripts = document.getElementsByTagName("script")[0]

        script.src = "//code.jivosite.com/widget.js"
        script.setAttribute("data-jv-id", "hDArvg0jmJ")
        script.async = "true"
        if (scripts) {
          scripts.parentNode.insertBefore(script, scripts)
        }
      }
    }

    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    const loadFunc = () => setLoaded(true)
    if (hasWindow() && !isLoaded) {
      window.addEventListener("load", loadFunc)

      const loadingShade = document.getElementById("loading-shade")

      if (loadingShade) {
        loadingShade.remove()
      }
    }

    return () => {
      if (hasWindow()) {
        window.removeEventListener("load", loadFunc)
      }
    }
  }, [isLoaded])

  return (
    <LoadContextProvider value={isLoaded}>
      <PageLoader loading={!isLoaded} />
      {children}
      <Cookies />
      <WhatsappFloatingButton />
    </LoadContextProvider>
  )
}

export default Layout
