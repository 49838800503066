import React from "react"
import cx from "classnames"

import StaticLogoPNG from "../../../images/logo.png"
import * as styles from "./assets/Logo.module.scss"
import useDomainName from "../../../hook/useDomainName"

const Logo = ({
  animated,
  size,
  showCompanyName,
  companyNameClassNames,
  withBackground,
  lightBackground,
  ...props
}) => {
  const isAnimated = animated ?? true
  if (!isAnimated) return <img {...props} src={StaticLogoPNG} />
  const { shortCompanyName } = useDomainName()

  return (
    <div className={styles.wrapper}>
      <div
        {...props}
        style={{ width: size, height: size }}
        className={cx(
          styles.container,
          !!withBackground ? styles.notTransparent : "",
          !!lightBackground ? styles.lightBackground : "",
        )}
      >
        <div className={cx(styles.cube, styles.top)} />
        <div className={cx(styles.cube, styles.left)} />
        <div className={cx(styles.cube, styles.right)} />
        <div className={cx(styles.cube, styles.bottom)} />
      </div>
      {showCompanyName && shortCompanyName && (
        <p className={styles.companyName}>{shortCompanyName}</p>
      )}
    </div>
  )
}

export default Logo
