// TODO: избавиться от большинства этих проверок, заменив на hook useDomainName

const hasWindow = () => typeof window !== "undefined"

const getHostName = () =>
  process.env.GATSBY_SITE_DOMAIN ||
  (hasWindow() ? window?.location?.host : false)

const isBelarusSite = hasWindow()
  ? getHostName().includes("by.sava.team")
  : false
const isUSASite = hasWindow() ? getHostName().includes("usava.team") : false

const isMainSite =
  process.env.GATSBY_SITE_DOMAIN === "sava.team" ||
  (!isBelarusSite && !isUSASite)

const getCompanyName = (short = true) => {
  if (hasWindow()) {
    let name = "SaVa"
    if (isUSASite) {
      name = "USAva"
    }

    return `${name}${!short ? ".Team" : ""}`
  }

  return ""
}

const getPhoneNumber = (formatted = false, domain) => {
  // const formattedRusNumber = formatted ? '+7 (985) 038-36-39' : '+79850383639';
  const formattedGreeceNumber = formatted
    ? "+995 (571) 16 88 33"
    : "+995571168833"
  const formattedUSANumber = formatted ? "+1 708 367 6763" : "+17083676763"
  const formattedBelarusNumber = formatted
    ? "+375 (29) 771 08 55"
    : "+375297710855"

  switch (domain) {
    case "by.sava.team":
      return formattedBelarusNumber
    case "sava.team":
      return formattedGreeceNumber
    case "usava.team":
      return formattedUSANumber
  }

  if (hasWindow()) {
    return isMainSite ? formattedGreeceNumber : formattedUSANumber
  }

  return ""
}

export {
  getCompanyName,
  getHostName,
  hasWindow,
  getPhoneNumber,
  isBelarusSite,
  isUSASite,
  isMainSite,
}
