import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

import { isLocalStorageSupported } from "./helpers/checkSupport"

import ru from "./locales/ru.json"
import en from "./locales/en.json"
import de from "./locales/de.json"

function initI18n() {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources: {
        en: {
          translations: en,
        },
        ru: {
          translations: ru,
        },
        // de: {
        //   translations: de,
        // },
      },
      fallbackLng: "en",
      lng:
        (isLocalStorageSupported() && localStorage.getItem("i18nextLng")) ||
        "en",
      debug: false,
      ns: ["translations"],
      defaultNS: "translations",
      keySeparator: false,
      nsSeparator: false,
      interpolation: {
        escapeValue: false,
      },
    })
}
export default initI18n
