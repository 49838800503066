import React from "react"
import { useTranslation } from "react-i18next"

import useDomainName from "../../../hook/useDomainName"

const FooterCopyright = () => {
  const { isUSASite, isMainSite, isBelarusSite } = useDomainName()
  const { t } = useTranslation()

  const getCompanyName = () => {
    if (isUSASite) return "USAva Inc."
    if (isBelarusSite) return "ООО «СаВа Тим»"
    return "SaVa.Team"
  }

  return (
    <div className="copyright">
      &copy; 2015-{new Date().getFullYear()} {getCompanyName()} &nbsp;
      {t(
        "All Rights Reserved. All other trademarks are property of their respective owners.",
      )}
      {isUSASite && <div>67 Oakwood Rd, Lake Zurich, IL, 60047, USA</div>}
    </div>
  )
}

export default FooterCopyright
