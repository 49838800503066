import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import cx from "classnames"
import { Stack } from "@mui/material"
import { useTranslation } from "react-i18next"

import LangSwitcher from "../langSwitcher"
// import ThemeToggler from "../ThemeToggler"
import Menu from "../Menu/Menu"
import PhoneMenu from "../PhoneMenu/PhoneMenu"
import Logo from "../UI/Logo"
import useDomainName from "../../hook/useDomainName"
import useWindowSize from "../../hook/useWindowSize"
import useScrollPosition from "../../hook/useScrollPosition"

import "./HeaderTop.scss"

function index({ navigatorMenu }) {
  const screen = useWindowSize()
  const scrollPosition = useScrollPosition()
  const { i18n } = useTranslation()
  const pathname = typeof window !== "undefined" ? window.location.pathname : ""
  const { isUSASite, phone, formattedPhone } = useDomainName()
  const phoneNumbers = [
    { phone: phone, formatted: formattedPhone },
    { phone: "+79850383639", formatted: "+7 (985) 038-36-39" },
  ]
  const [currentPhoneIndex, setCurrentPhoneIndex] = useState(0)
  const [animation, setAnimation] = useState(true)

  useEffect(() => {
    let interval

    if (i18n?.language === "ru") {
      setAnimation(false)

      interval = setInterval(() => {
        setCurrentPhoneIndex(prevIndex => (prevIndex === 0 ? 1 : 0))
        setAnimation(true)
      }, 5000)
    } else if (interval) {
      clearInterval(interval)
    }

    return () => clearInterval(interval)
  }, [i18n])

  return (
    <div
      className={cx(
        "header__top",
        scrollPosition > 100 && pathname === "/" ? "header__top_fixed" : "",
        pathname !== "/" ? "header__top_fixed" : "",
      )}
    >
      <Link className="logo" to="/">
        <Logo size={50} showCompanyName lightBackground />
      </Link>

      <Menu
        style={{
          display: screen.width >= 991 && navigatorMenu ? "flex" : "none",
        }}
      />

      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          gap: 4,
          position: "absolute",
          right: "16px",
        }}
      >
        {screen.width >= 991 || !navigatorMenu ? (
          <>
            {isUSASite ? (
              <>
                {/*TODO: вынести второй телефон в конфиг*/}
                <a
                  data-telegram="usava_team"
                  href="tel:+18475046041"
                  className="header__phone"
                >
                  +1 847 504 6041
                </a>
              </>
            ) : (
              <>
                {i18n?.language === "ru" ? (
                  <a
                    data-telegram="sava_team"
                    href={`tel:${phoneNumbers[currentPhoneIndex].phone}`}
                    className="header__phone"
                  >
                    {phoneNumbers[currentPhoneIndex].formatted}
                  </a>
                ) : (
                  <a
                    data-telegram="sava_team"
                    href={`tel:${phone}`}
                    className={`header__phone ${animation ? "slide-in" : "slide-out"}`}
                  >
                    {formattedPhone}
                  </a>
                )}
              </>
            )}

            <LangSwitcher />
            {/* <ThemeToggler /> */}
          </>
        ) : (
          ""
        )}
      </Stack>
      <div className={screen.width > 991 ? "hidden" : ""} />
      <PhoneMenu className={!navigatorMenu ? "hidden" : ""} />

      {/*{(window.location.pathname === '/magento' && !this.state.isPortativeDevice) ? (*/}
      {/*  <>*/}
      {/*    <Link className="header__special" to="/moving-from-magento-1-to-magento-2-upgrade-service">*/}
      {/*      Magento 2.3.x*/}
      {/*    </Link>*/}
      {/*  </>*/}
      {/*) : ''}*/}
    </div>
  )
}

export default index
