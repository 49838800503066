import React, { useEffect, useState } from "react"
import { AnimatePresence, motion } from "framer-motion"

import Logo from "./UI/Logo"

const PageLoader = ({ loading }) => {
  const [endLoadingAnimation, setEndLoadingAnimation] = useState(true)

  useEffect(() => {
    setEndLoadingAnimation(loading)
  }, [loading])

  return (
    <>
      <AnimatePresence>
        {endLoadingAnimation && (
          <motion.div
            exit={{ opacity: 0 }}
            transition={{ duration: 1.2 }}
            className="page-loader"
          >
            <AnimatePresence>
              {loading && (
                <motion.div
                  exit={{ scale: 0 }}
                  transition={{
                    duration: 1,
                  }}
                  className="logo-loader"
                >
                  <motion.div
                    animate={{ rotate: [0, 90, 180, 270, 360] }}
                    transition={{
                      duration: 8,
                      repeat: Infinity,
                    }}
                  >
                    <Logo withBackground showCompanyName={false} size={100} />
                  </motion.div>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default PageLoader
