import { configureStore } from "@reduxjs/toolkit"
import scrollReducer from "./Slices/scroll"
import contactsReducer from "./Slices/contacts"

export const store = configureStore({
  reducer: {
    scroll: scrollReducer,
    contacts: contactsReducer,
  },
})
