import { createSlice } from "@reduxjs/toolkit"

const contactsSlice = createSlice({
  name: "contacts",
  initialState: {
    promoCode: "",
    message: "",
    selectedPlanName: "",
    submitMessage: "",
  },
  reducers: {
    setPromoCode: (state, action) => {
      state.promoCode = action.payload
    },
    setMessage: (state, action) => {
      state.message = action.payload
    },
    setSubmitMessage: (state, action) => {
      state.submitMessage = action.payload
    },
    putSubmitMessage: (state, action) => {
      state.submitMessage += "\n" + action.payload
    },
    setSelectedPlanName: (state, action) => {
      state.selectedPlanName = action.payload
    },
  },
})

export const {
  setPromoCode,
  setMessage,
  setSubmitMessage,
  putSubmitMessage,
  setSelectedPlanName,
} = contactsSlice.actions

export default contactsSlice.reducer
