import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import cx from "classnames"

import * as s from "./langSwitcher.module.scss"
import useDomainName from "../../hook/useDomainName"

function LangSwitcher() {
  const [open, setOpen] = useState(false)
  const { t, i18n } = useTranslation()
  const { isBelarusSite, isMainSite, isLanguageSelect, langSlug } =
    useDomainName()
  const changeLanguage = lng => {
    i18n.changeLanguage(lng)
  }
  const modalHandler = () => {
    setOpen(!open)
  }
  const [languages] = useState([
    {
      name: "English",
      value: "en",
    },
    // {
    //   name: "Deutsch",
    //   value: "de",
    // },
    {
      name: "Русский",
      value: "ru",
    },
  ])

  return (
    <div
      onClick={modalHandler}
      className={
        open ? cx(s.langSwitcher, s.langSwitcher_active) : s.langSwitcher
      }
      style={{ display: isLanguageSelect ? "" : "none" }}
    >
      <p>{i18n?.language?.toUpperCase()}</p>
      <span className={s.arrow} />

      {open && (
        <div className={s.dropdown}>
          {languages.map((lang, value) => (
            <div
              key={value}
              onClick={e => {
                changeLanguage(lang.value)
                modalHandler()
              }}
              className={i18n.language == lang.value && s.dropdown__strong}
            >
              {lang.name}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default LangSwitcher
