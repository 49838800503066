import React from "react"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"

import "./FooterMenu.scss"
import MenuContacts from "./components/menuContacts"
import useDomainName from "../../hook/useDomainName"

function FooterMenu() {
  const { isUSASite } = useDomainName()

  if (isUSASite) return <MenuUSA />
  return <MenuDefault />
}

const MenuUSA = () => {
  const { t } = useTranslation()

  return (
    <>
      <ul className="menu menu_footer">
        <li className="menu__item">{t("Information")}</li>
        <li className="menu__item">
          <Link to="/terms-of-use">{t("Terms of use")}</Link>
        </li>
        <li className="menu__item">
          <Link to="/personal-data-processing-policy">
            {t("Personal data processing policy")}
          </Link>
        </li>
      </ul>
      <MenuContacts />
    </>
  )
}

const MenuDefault = () => {
  const { t } = useTranslation()

  return (
    <>
      <ul className="menu menu_footer">
        <li className="menu__item">{t("Information")}</li>
        <li className="menu__item">
          <Link to="/payment-methods">{t("Payment methods")}</Link>
        </li>
        <li className="menu__item">
          <Link to="/requisites">{t("Requisites")}</Link>
        </li>
        <li className="menu__item">
          <Link to="/cookie-policy">{t("Cookie policy")}</Link>
        </li>
        <li className="menu__item">
          <Link to="/terms-of-use">{t("Terms of use")}</Link>
        </li>
        <li className="menu__item">
          <Link to="/personal-data-processing-policy">
            {t("Personal data processing policy")}
          </Link>
        </li>
        {/*<li className="menu__item"><Link to="/terms-conditions">{t('Terms & Conditions')}</Link></li>*/}
        {/*<li className="menu__item">*/}
        {/*  <Link to="/license-agreement">{t('License Agreement')}</Link>*/}
        {/*</li>*/}
      </ul>
      <MenuContacts />
      {/*<ul className="menu menu_footer">*/}
      {/*  <li className="menu__item">{t('About')}</li>*/}
      {/*  <li className="menu__item"><a href="/about">{t('About SaVa')}</a></li>*/}
      {/*  /!*<li className="menu__item"><a href="#">{t('Careers SaVa')}</a></li>*!/*/}
      {/*  /!*<li className="menu__item"><a href="#">{t('Help')}</a></li>*!/*/}
      {/*  <li className="menu__item"><a href="/legal/privacy">{t('Privacy Policy')}</a></li>*/}
      {/*  <li className="menu__item"><a href="/legal/terms">{t('Terms & Conditions')}</a></li>*/}
      {/*</ul>*/}
    </>
  )
}

export default FooterMenu
