// extracted by mini-css-extract-plugin
export var bottom = "Logo-module--bottom--bafb3";
export var companyName = "Logo-module--companyName--d0bf1";
export var container = "Logo-module--container--41842";
export var cube = "Logo-module--cube--e5ccd";
export var left = "Logo-module--left--34650";
export var lightBackground = "Logo-module--lightBackground--e7286";
export var logoAnimateCube = "Logo-module--logo-animate-cube--641de";
export var notTransparent = "Logo-module--notTransparent--7941b";
export var right = "Logo-module--right--dc912";
export var top = "Logo-module--top--a5c66";
export var wrapper = "Logo-module--wrapper--0f607";