import { useStaticQuery, graphql } from "gatsby"
import { hasWindow } from "../helpers/config"

const useDomainName = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          domain
        }
      }
    }
  `)

  const hostName =
    data.site.siteMetadata.domain ||
    (hasWindow() ? window?.location?.host : false)
  const isBelarusSite = hostName.includes("by.sava.team") || false
  const isUSASite = hostName.includes("usava.team") || false
  const isMainSite = hostName === "sava.team" || (!isBelarusSite && !isUSASite)
  const companyName = getCompanyName(hostName)
  const shortCompanyName = getCompanyName(hostName, true)
  const phone = getPhoneNumber(hostName)
  const formattedPhone = getPhoneNumber(hostName, true)
  // Вынести в env
  const langSlug = isUSASite ? "en" : "ru"
  const currency = getCurrencyByDomain(hostName)
  const isLanguageSelect = checkIsLanguageSelect({
    isMainSite,
    isUSASite,
    isBelarusSite,
  })

  return {
    hostName,
    isMainSite,
    isUSASite,
    isBelarusSite,
    companyName,
    shortCompanyName,
    phone,
    formattedPhone,
    langSlug,
    currency,
    isLanguageSelect,
  }
}

// TODO: Переписать на switch
const checkIsLanguageSelect = ({ isMainSite, isBelarusSite, isUSASite }) =>
  !isMainSite ? !isBelarusSite && !isUSASite : true

function getCompanyName(hostName, short = false) {
  let name = ""
  switch (hostName) {
    case "by.sava.team":
      name = "SaVa"
      break
    case "usava.team":
      name = "USAva"
      break
    case "sava.team":
    default:
      name = "SaVa"
  }

  return `${name}${!short ? ".Team" : ""}`
}

const getPhoneNumber = (domain, formatted = false) => {
  // const formattedRusNumber = formatted ? '+7 (985) 038-36-39' : '+79850383639';
  const formattedGreeceNumber = formatted
    ? "+995 (571) 16 88 33"
    : "+995571168833"
  const formattedUSANumber = formatted ? "+1 708 367 6763" : "+17083676763"
  const formattedBelarusNumber = formatted
    ? "+375 (29) 771 08 55"
    : "+375297710855"

  switch (domain) {
    case "by.sava.team":
      return formattedBelarusNumber
    case "sava.team":
      return formattedGreeceNumber
    case "usava.team":
      return formattedUSANumber
  }

  if (hasWindow()) {
    return domain === "sava.team" ? formattedGreeceNumber : formattedUSANumber
  }

  return ""
}

const getCurrencyByDomain = hostName => {
  switch (hostName) {
    case "by.sava.team":
      return "byn"
    case "usava.team":
      return "usd"
    case "sava.team":
    default:
      return "rub"
  }
}

export default useDomainName
